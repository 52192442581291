// styles/fonts.tsx

import { Global, css } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'robotobold';
        src:
          url('/fonts/roboto-bold-webfont.woff') format('woff'),
          url('/fonts/roboto-bold-webfont.eot') format('eot'),
          url('/fonts/roboto-bold-webfont.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'robotomedium';
        src:
          url('/fonts/roboto-medium-webfont.woff') format('woff'),
          url('/fonts/roboto-medium-webfont.eot') format('eot'),
          url('/fonts/roboto-medium-webfont.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'robotoregular';
        src:
          url('/fonts/roboto-regular-webfont.woff') format('woff'),
          url('/fonts/roboto-regular-webfont.eot') format('eot'),
          url('/fonts/roboto-regular-webfont.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'robotolight';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src:
          url('/fonts/Roboto-Light-webfont.woff') format('woff'),
          url('/fonts/roboto-light-webfont.woff2') format('woff2'),
          url('/fonts/roboto-light-webfont.eot') format('eot'),
          url('/fonts/roboto-light-webfont.ttf') format('ttf');
      }

      @font-face {
        font-family: 'sacramentoregular';
        src:
          url('/fonts/sacramento-regular-webfont.woff2') format('woff2'),
          url('/fonts/sacramento-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'blenda_scriptregular';
        src:
          url('/fonts/blenda_script-webfont.woff2') format('woff2'),
          url('/fonts/blenda_script-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Open Sans';
        src:
          url('/fonts/OpenSans-Regular.woff') format('woff'),
          url('/fonts/OpenSans-Regular.eot') format('eot'),
          url('/fonts/OpenSans-Regular.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Open Sans';
        src:
          url('/fonts/OpenSans-SemiBold.woff') format('woff'),
          url('/fonts/OpenSans-SemiBold.eot') format('eot'),
          url('/fonts/OpenSans-SemiBold.ttf') format('ttf');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'pangramlight';
        src:
          url('/fonts/pangram/pangram-light-webfont.woff2') format('woff2'),
          url('/fonts/pangram/pangram-light-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'pangrammedium';
        src: url('/fonts/pangram/pangram-medium-webfont.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'pangramregular';
        src: url('/fonts/pangram/pangram-regular-webfont.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'pangrambold';
        src:
          url('/fonts/pangram/pangram-bold-webfont.woff2') format('woff2'),
          url('/fonts/pangram/pangram-bold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'pangramblack';
        src:
          url('/fonts/pangram/pangram-black-webfont.woff2') format('woff2'),
          url('/fonts/pangram/pangram-black-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Spartan', sans-serif;
        src:
          url('/fonts/spartan-variablefont_wght-webfont.woff2') format('woff2'),
          url('/fonts/spartan-variablefont_wght-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Abhay-Libre Regular';
        src:
          url('/fonts/abhayalibre-regular-webfont.woff2') format('woff2'),
          url('/fonts/abhayalibre-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Abhay-Libre medium';
        src:
          url('/fonts/abhayalibre-medium-webfont.woff2') format('woff2'),
          url('/fonts/abhayalibre-medium-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Abhay-Libre Bold';
        src:
          url('/fonts/latest/abhayalibre-bold-webfont.woff2') format('woff2'),
          url('/fonts/abhayalibre-bold-webfont.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Abhay-Libre Extra Bold';
        src:
          url('/fonts/abhayalibre-extrabold-webfont.woff2') format('woff2'),
          url('/fonts/abhayalibre-extrabold-webfont.woff') format('woff');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Lato Regular';
        src:
          url('/fonts/lato-regular-webfont.woff2') format('woff2'),
          url('/fonts/lato-regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Lato Bold';
        src:
          url('/fonts/lato-bold-webfont.woff2') format('woff2'),
          url('/fonts/lato-bold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
);

export default Fonts;
